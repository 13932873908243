import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: 'term-sheet',
    name: 'term-sheet.index',
    component: () => import('./views/pages/Index.vue'),
    children: [
      {
        path: ':id',
        component: () => import('./views/modals/Show.vue'),
        children: [
          {
            path: 'costs',
            name: 'term-sheet.show',
            component: () => import('./views/tabs/Costs.vue'),
          },
          {
            path: 'costs',
            name: 'term-sheet.edit',
            component: () => import('./views/tabs/Costs.vue'),
          },
          {
            path: 'media',
            name: 'term-sheet.media',
            component: () => import('./views/tabs/Media.vue'),
          },
          {
            path: 'notes',
            name: 'term-sheet.notes',
            component: () => import('./views/tabs/Notes.vue'),
          },
          {
            path: 'log',
            name: 'term-sheet.log',
            component: () => import('./views/tabs/Log.vue'),
          },
        ],
        meta: {
          delay: true,
          permission: 'contract.show',
        },
      },
    ],
    meta: {
      title: 'Systeem instellingen / Contracten',
      permission: 'contract.index',
    },
  },
];
