import { jsonApi } from '@libero/utilities/api-client';
import { echo, UnsubscribeCallback } from '@libero/utilities/echo-client';

import type { Notification } from './types';

type SubscribeCallback = (notification: Notification) => void;

const notification = jsonApi('auth/user/notifications');

const index = (): Promise<Notification[]> => {
  return notification.get();
};

const subscribe = (userId: string, callback: SubscribeCallback): UnsubscribeCallback => {
  const channel = echo.private(`App.Models.User.${userId}`).notification(callback);

  return () => {
    return channel.stopListening(
      '.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
    );
  };
};

const markAsRead = (id?: string | number) => (): Promise<void> => {
  return notification.put(id?.toString());
};

const destroy = (id: string | number): Promise<void> => {
  return notification.delete(id.toString());
};

export const notificationApi = {
  name: 'notification',
  index,
  subscribe,
  markAsRead,
  destroy,
};
