<script lang="ts" setup>
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { notificationApi } from '@libero/api-client/notification/api';
import { Notification } from '@libero/api-client/notification/types';
import NotificationDropdown from '@libero/cityview/modules/notification/components/NotificationDropdown/NotificationDropdown.vue';
import Avatar from '@libero/ui-framework/Avatar/Avatar.vue';
import Badge from '@libero/ui-framework/Badge/Badge.vue';
import Clickable from '@libero/ui-framework/Clickable/Clickable.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import IconButton from '@libero/ui-framework/IconButton/IconButton.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { classNames } from '@libero/utilities/class-names';
import { formatDistance } from '@libero/utilities/date';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { RouterLocation, useRouter } from 'vue-router';

export interface Props {
  notification: Notification;
  to?: RouterLocation;
  href?: string;
  isToast?: boolean;
  onClick?: (event: Event) => void;
  onClose?: () => void;
}

const props = defineProps<Props>();

const router = useRouter();
const queryClient = useQueryClient();

const { mutateAsync: handleMarkAsRead } = useMutation({
  mutationFn: notificationApi.markAsRead(props.notification.id),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['notification.index'] });
  },
  onError: () => {
    queryClient.invalidateQueries({ queryKey: ['notification.index'] });
  },
});

const handleClick = (event: Event) => {
  handleMarkAsRead();

  if (props.to) {
    return router.push(props.to);
  }

  if (props.href) {
    return window.open(props.href, '_blank');
  }

  props.onClick?.(event);
};

const handleClose = (event: Event) => {
  event.stopPropagation();
  props.onClose?.();
};
</script>

<template>
  <Clickable
    class="base-notification"
    :class="classNames({ isToast })"
    :href="href"
    :onClick="handleClick"
  >
    <Cluster>
      <Cluster :gap="0.5">
        <Badge :isInvisible="!!notification.read_at" />

        <Avatar :size="isToast ? 'sm' : undefined" outline="dark" isLight>
          <slot name="icon" />
        </Avatar>
      </Cluster>

      <Cluster flex="1">
        <Stack width="100%" :gap="0.25">
          <slot />

          <Typography size="sm" muted>
            {{ formatDistance(props.notification.created_at) }}
          </Typography>
        </Stack>

        <template v-if="isToast">
          <IconButton
            v-if="onClose"
            class="base-notification-close"
            size="xs"
            appearance="link"
            color="secondary"
            :onClick="handleClose"
          >
            <XMarkIcon />
          </IconButton>
        </template>

        <NotificationDropdown v-else :notification="notification" />
      </Cluster>
    </Cluster>
  </Clickable>
</template>

<style lang="scss" scoped>
@import '@libero/cityview/modules/notification/components/Notification/Adapters/BaseNotification.scss';
</style>
