export enum UserSettingsLocale {
  NL = 'nl',
  EN = 'en',
}

export enum UserSettingsFormat {
  Default = 'DD-MM-YYYY HH:mm',
  English = 'YYYY-MM-DD HH:mm',
  Advanced = 'dddd DD MMMM YYYY HH:mm',
}

export enum UserSettingsTimezone {
  Amsterdam = 'Europe/Amsterdam',
  Berlin = 'Europe/Berlin',
  Brussels = 'Europe/Brussels',
  Luxembourg = 'Europe/Luxembourg',
  London = 'Europe/London',
}

export enum UserSettingsTimeout {
  Hour = 60,
  Hours2 = 120,
  Hours4 = 240,
  Hours8 = 480,
  Hours12 = 720,
  Hours24 = 1440,
}

export enum UserSettingsTicketsTab {
  JobSheet = 'job-sheet',
  Map = 'map',
  Relations = 'relations',
  Media = 'media',
  Tickets = 'tickets',
  Containers = 'containers',
  Notes = 'notes',
  LeadTimes = 'lead-times',
  Log = 'log',
}

export interface UserNotificationSubscription {
  id: number;
  notification_type: string;
  via: ('mail' | 'vonage' | 'database')[];
}

export interface StoreUserProfileRequest {
  firstname: string | null;
  lastname: string | null;
  phone: string | null;
}

export interface StoreUserPreferencesRequest {
  locale?: UserSettingsLocale | null;
  datetime_format?: UserSettingsFormat | null;
  timezone?: UserSettingsTimezone | null;
  session_timeout?: UserSettingsTimeout | null;
  tickets_tab?: UserSettingsTicketsTab | null;
  tickets_fields_order?: string[][] | null;
}

export type StoreUserNotificationSubscriptionsRequest = {
  [key: `${string}_enabled`]: boolean;
  [key: `subscription_${string}_enabled`]: boolean;
  notification_subscriptions: {
    [key: number]: string[];
  };
};
