<script setup lang="ts">
import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/vue/24/solid';
import type { Resource } from '@libero/api-client/types/resource';
import type { ResourceApi } from '@libero/api-client/types/resource-api';
import { useUserStore } from '@libero/hooks/store/useUserStore';
import DestroyModal from '@libero/organisms/DestroyModal/DestroyModal.vue';
import Dropdown from '@libero/ui-framework/Dropdown/Dropdown.vue';
import DropdownAction from '@libero/ui-framework/Dropdown/DropdownAction.vue';
import DropdownBody from '@libero/ui-framework/Dropdown/DropdownBody.vue';
import DropdownFooter from '@libero/ui-framework/Dropdown/DropdownFooter.vue';
import DropdownHeader from '@libero/ui-framework/Dropdown/DropdownHeader.vue';
import IconButton from '@libero/ui-framework/IconButton/IconButton.vue';
import Tooltip from '@libero/ui-framework/Tooltip/Tooltip.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { QueryKey } from '@tanstack/vue-query';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  title?: string;
  subject?: string;
  item: Pick<Resource, 'id'>;
  resourceApi: Pick<ResourceApi, 'name' | 'permissionBase'> &
    Partial<Pick<ResourceApi, 'destroy' | 'replicate'>>;
  deleteInvalidateKeys?: QueryKey[];
  isAlwaysOpen?: boolean;
  hasNoDelete?: boolean;
  resourceTranslation?: string;
  onDisconnect?: (id: string | number) => void;
  disconnectDisabledMessage?: string | undefined;
}

const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  subject: undefined,
  deleteInvalidateKeys: undefined,
  resourceTranslation: undefined,
  onDisconnect: undefined,
  disconnectDisabledMessage: undefined,
});

const { t } = useI18n();
const { hasPermission } = useUserStore();

const permissionBase = computed(() => props.resourceApi.permissionBase || props.resourceApi.name);

const showFooter = computed(
  () =>
    (hasPermission(`${permissionBase.value}.destroy`) && !props.hasNoDelete) ||
    (hasPermission(`${permissionBase.value}.scout`) && props.onDisconnect),
);
</script>

<template>
  <Dropdown :isAlwaysOpen="isAlwaysOpen">
    <template #trigger>
      <span @click.stop>
        <IconButton size="sm" appearance="link" color="secondary">
          <EllipsisVerticalIcon />
        </IconButton>
      </span>
    </template>

    <template #content>
      <DropdownHeader v-if="title">
        <Typography tag="h5" type="heading" size="xxs" bold>
          {{ title }}
        </Typography>
      </DropdownHeader>

      <DropdownBody>
        <slot />
      </DropdownBody>

      <DropdownFooter v-if="showFooter">
        <Tooltip
          v-if="onDisconnect"
          :content="disconnectDisabledMessage"
          :disabled="!disconnectDisabledMessage"
        >
          <DropdownAction
            :isDisabled="!!disconnectDisabledMessage"
            color="error"
            :onClick="() => onDisconnect?.(item.id)"
          >
            <TrashIcon class="dropdown-action-icon" />
            {{ t('disconnect') }}
          </DropdownAction>
        </Tooltip>

        <DestroyModal
          v-else-if="resourceApi.destroy"
          v-slot="{ openModal }"
          :subject="subject"
          :item="item"
          :resourceApi="resourceApi"
          :invalidateKeys="deleteInvalidateKeys"
          :resourceTranslation="resourceTranslation"
        >
          <DropdownAction color="error" :onClick="openModal">
            <TrashIcon class="dropdown-action-icon" />
            {{ t('destroy') }}
          </DropdownAction>
        </DestroyModal>
      </DropdownFooter>
    </template>
  </Dropdown>
</template>
