import { RouteRecordRaw } from 'vue-router';

import SystemSettingsLayout from './views/layouts/SystemSettingsLayout/SystemSettingsLayout.vue';

export const systemSettingsModules = [
  'user',
  'organisation',
  'role',
  'integration',
  'classification',
  'ticket-subject',
  'cause',
];

const children = Object.values(
  import.meta.glob(
    `../(user|organisation|role|integration|classification|ticket-subject|cause)/routes.ts`,
    {
      eager: true,
    },
  ) as Record<string, { routes: RouteRecordRaw[] }>,
)
  .map((routes) => routes.routes)
  .flat();

export const routes: RouteRecordRaw[] = [
  {
    path: '/system-settings',
    component: SystemSettingsLayout,
    children: [
      ...children,
      {
        path: '',
        redirect: { name: 'user.index' },
      },
    ],
  },
];
