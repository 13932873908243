import { authUserApi } from '@libero/api-client/auth-user/api';
import { notificationApi } from '@libero/api-client/notification/api';
import { Theme } from '@libero/api-client/theme/types';
import type { User } from '@libero/api-client/user/types';
import { setLocale } from '@libero/plugins/dayjs';
import { setSentryUser } from '@libero/plugins/sentry';
import { message } from 'ant-design-vue';
import { isArray, isEmpty } from 'lodash';
import { defineStore } from 'pinia';
import { type Ref, ref } from 'vue';

import { useTenantStore } from './useTenantStore';

interface State {
  user: Ref<User | undefined>;
  fetchUser: () => Promise<void>;
  reset: () => void;
  hasPermission: (name: string) => boolean;
  hasThemePermission: (name: string, theme: Theme | undefined | null) => boolean;
  hasThemesPermission: (name: string, theme: Theme[]) => boolean;
  themesForPermission: (name: string) => string | undefined;
}

const allowedScopes = [notificationApi.name];

export const useUserStore = defineStore('user', (): State => {
  const tenantStore = useTenantStore();

  const user = ref<User>();

  const fetchUser = async () => {
    user.value = await authUserApi.user().catch((error) => {
      if (error.response && error.response.status !== 451) {
        message.error(error.response.data.message);
      }

      return undefined;
    });

    if (user.value) {
      setLocale(user.value.settings.locale);
      setSentryUser(user.value);
      tenantStore.fetchTenant();
    }
  };

  const reset = () => {
    user.value = undefined;
  };

  const hasPermission = (name: string) => {
    if (allowedScopes.find((scope) => name.startsWith(scope))) {
      return true;
    }

    if (!user.value?.roles) return false;

    return user.value.roles.some((role) =>
      role.permissions.some((permission) => permission.name === name),
    );
  };

  const hasThemePermission = (name: string, theme?: Theme | Theme[] | undefined | null) =>
    user.value?.roles.some(
      (role) =>
        role.permissions.some((permission) => permission.name === name) &&
        (!role.theme ||
          !theme ||
          (isArray(theme)
            ? theme.every((item) => item.theme?.id === role.theme?.id)
            : role.theme?.id === theme.id)),
    ) || false;

  const hasThemesPermission = (name: string, themes: Theme[]) =>
    isEmpty(themes)
      ? hasThemePermission(name)
      : themes.every((theme) => hasThemePermission(name, theme)) || false;

  const themesForPermission = (name: string): string | undefined => {
    if (!user.value?.roles) return undefined;

    const themeIds = user.value.roles
      .filter((role) => role.permissions.some((permission) => permission.name === name))
      .map((role) => role.theme?.id)
      .filter(Boolean);

    if (!themeIds.length) return undefined;

    return themeIds.join(',');
  };

  return {
    user,
    fetchUser,
    reset,
    hasPermission,
    hasThemePermission,
    hasThemesPermission,
    themesForPermission,
  };
});
