<script setup lang="ts">
import { Notification as NotificationType } from '@libero/api-client/notification/types';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Modal from '@libero/ui-framework/Modal/Modal.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import Notification from '../../components/Notification/Notification.vue';

interface Props {
  notifications: NotificationType[];
  onRead: (id?: string) => void;
}

const props = defineProps<Props>();

const { t } = useI18n();

const isOpen = ref(true);

const toggleVisible = () => {
  isOpen.value = !isOpen.value;
};

const handleMarkAllAsRead = () => {
  toggleVisible();

  props.notifications.forEach((notification) => {
    props.onRead(notification.id);
  });
};
</script>

<template>
  <Modal
    :title="t('notification.important-notifications')"
    :isOpen="isOpen"
    :onCancel="toggleVisible"
  >
    <Stack :gap="0">
      <Stack :gap="0">
        <Notification
          v-for="notification in notifications"
          :key="notification.id"
          :notification="notification"
        />
      </Stack>

      <Cluster justifyContent="center">
        <Button appearance="link" color="secondary" :onClick="handleMarkAllAsRead">
          {{ t('notification.mark-all-as-read') }}
        </Button>
      </Cluster>
    </Stack>
  </Modal>
</template>
