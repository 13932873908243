import { jsonApi } from '@libero/utilities/api-client';

import { User } from '../user/types';
import type {
  StoreUserNotificationSubscriptionsRequest,
  StoreUserPreferencesRequest,
  StoreUserProfileRequest,
  UserNotificationSubscription,
} from './types';

const authUser = jsonApi('auth/user');

const user = (): Promise<User> => {
  return authUser.get();
};

const updateProfile = (data: StoreUserProfileRequest): Promise<unknown> => {
  return authUser.put('profile', data);
};

const updatePreferences = (data: StoreUserPreferencesRequest): Promise<unknown> => {
  return authUser.put('settings', data);
};

const getNotificationSubscriptions = (): Promise<UserNotificationSubscription[]> => {
  return authUser.get('notification-subscriptions');
};

const updateNotificationSubscriptions = (
  data: StoreUserNotificationSubscriptionsRequest,
): Promise<unknown> => {
  return authUser.put('notification-subscriptions', data);
};

export const authUserApi = {
  name: 'auth-user',
  user,
  updateProfile,
  updatePreferences,
  getNotificationSubscriptions,
  updateNotificationSubscriptions,
};
