<script lang="ts" setup>
import { InboxIcon } from '@heroicons/vue/24/solid';
import { NoteMentionNotification } from '@libero/api-client/notification/types';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { kebabCase } from 'lodash';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import BaseNotification, { Props } from './BaseNotification.vue';

const props = defineProps<Props>();

const { t } = useI18n();

const notification = computed(() => props.notification as NoteMentionNotification);

const modelName = computed(() => kebabCase(notification.value.data.model_name));
const modelId = computed(() => notification.value.data.model_id);
const parentId = computed(() => notification.value.data.parent_id);

const to = computed(() => {
  if (!notification.value) {
    return;
  }

  switch (modelName.value) {
    case 'ticket':
      return {
        name: 'ticket.show.notes',
        params: { id: modelId.value },
      };
    case 'container':
      return {
        name: 'container.notes',
        params: { id: modelId.value },
      };
    case 'term-sheet':
      return {
        name: 'term-sheet.notes',
        params: { contractId: parentId.value, id: modelId.value },
      };
    default:
      return undefined;
  }
});
</script>

<template>
  <BaseNotification v-bind="props" :notification="notification" :to="to">
    <template #icon>
      <InboxIcon />
    </template>

    <Typography>
      {{ t('notification.new-note-mention') }}

      <Typography isDark bold>
        {{ t(`${modelName}.${modelName}`) }}
      </Typography>
    </Typography>
  </BaseNotification>
</template>
