<script lang="ts" setup>
import { ArrowRightIcon } from '@heroicons/vue/16/solid';
import { ArrowPathIcon } from '@heroicons/vue/24/solid';
import { HelpdeskUpdateNotification } from '@libero/api-client/notification/types';
import { Color } from '@libero/types/Color';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import Link from '@libero/ui-framework/Link/Link.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import BaseNotification, { Props } from './BaseNotification.vue';

const { t } = useI18n();

const props = defineProps<Props>();

const notification = computed(() => props.notification as HelpdeskUpdateNotification);
</script>

<template>
  <BaseNotification v-bind="props" :notification="notification" :href="notification.data.url">
    <template #icon>
      <ArrowPathIcon />
    </template>

    <Stack :gap="0.375">
      <Stack :gap="0">
        <Typography isDark bold>
          {{ notification.data.title }}
        </Typography>

        <Typography v-if="notification.data.description">
          {{ notification.data.description }}
        </Typography>
      </Stack>

      <Cluster minHeight="1.25rem" alignItems="center" :gap="0.25">
        <Link size="sm" :href="notification.data.url" shouldOpenBlank bold>
          {{ t('notification.read-more') }}
        </Link>

        <Icon :color="Color.primary600">
          <ArrowRightIcon />
        </Icon>
      </Cluster>
    </Stack>
  </BaseNotification>
</template>
